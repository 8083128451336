//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import emailjs from 'emailjs-com';
import countdown from '../../utils/countdown'
import PageHeader from '../../layout/components/Header'
export default {
    name: 'Login',
	components: {PageHeader},
    data() {
        return {
			webset:[],
			uniacid:localStorage.getItem('uniacid'),

			loginMethod:'password',
            address: [
                {text: "香港",value: "852"},
                {text: "澳门",value: "853"},
                {text: "中国大陆",value: "86"},
		    ],
            form: {
                email: '',
                mobile: '',
                diqu: '852',
                password: '',
                repassword: '',
                code: '',
                action: 'login'
            },
            forgetpasswordform: {
                email: '',
                pw: ""
            },
            sendCodeText: '發送驗證碼',
			facebooklogin:false,
            fbSignInParams: {
                scope: 'email',
                return_scopes: true
            },
           locale: localStorage.getItem('locale'),
           dataList: [],
        }
    },
	created() {
		var ss = window.localStorage.getItem('webinfo');
		var ssarray = JSON.parse(ss)
		 
		this.webset =  ssarray
		this.uniacid = ssarray['ucid']
        //this.uniacid = 20
		if(ssarray['openfacebook']>0){
			this.facebooklogin = true 
		}
		this.form.uniacid = this.uniacid
        this.forgetpasswordform.uniacid = this.uniacid


        console.log("ss")
        console.log(ss)
		
		
	},
    methods: {
        async logInWithFB() {
            console.log("login in with FB")
            window.FB.getLoginStatus(function(response) {
                console.log(response);
            });

            window.FB.api('/me', function(response) {
                console.log(JSON.stringify(response));
            });
        },
        async logInWithFacebook() {
            //await this.loadFacebookSDK(document, "script", "facebook-jssdk");
            await this.initFacebook();
            let fb_user_id = ''
            let fb_name = ''
            let fb_token = '';
            await window.FB.login((response) => {
                if (response.authResponse) {

                    window.FB.api('/me', (response2) => {
                        console.log(JSON.stringify(response2));
                        fb_user_id = response.authResponse.userID;
                        fb_name = response2.name;
                        fb_token = response.authResponse.accessToken;

                        console.log(fb_user_id + ", " + fb_name + ", " + fb_token);
                        this.$message({ message: '您好' + JSON.stringify(fb_name) + '，登入Facebook成功',type: 'success'});
                        
                        let fdata= {
                            userid: fb_user_id
                        }

                        window.localStorage.setItem('fb_user_id', fb_user_id);
                        window.localStorage.setItem('fb_name', fb_name);
					 
                        this._loginFB(fdata);

                        /*
                        const data = {
                            "user_id": response.authResponse.userID,
                            "token": response.authResponse.accessToken
                        }
                        window.localStorage.setItem('user_id', response.authResponse.userID);
                        window.localStorage.setItem('login@token', response.authResponse.accessToken)
                        document.cookie = 'PHPSESSID=' + response.authResponse.accessToken;
                        //this.getUserInfo()
                        this.$store.commit('saveUserInfo',data);
                        window.location.hash = '/';*/

                                    
                    });

                   // const data = {
                    //    "user_id": response.authResponse.userID,
                    //    "token": response.authResponse.accessToken
                    //}
                    //this._loginSuccessCallback(data, true)
                } else {
                    this.$message({ message: 'User cancelled login or did not fully authorize.',type: 'warning'});
                }
            });

            

            /*
            window.localStorage.setItem('user_id', fb_user_id);
            window.localStorage.setItem('login@token', fb_token)
            document.cookie = 'PHPSESSID=' + fb_token;
            this.getUserInfo()
            this.$store.commit('saveUserInfo',data);

            window.location.hash = '/';*/

            return false;
        },
        async initFacebook() {
            var fblist = [
                {
                    "uniacid": 15, //Dr. Alex Au
                    "api": "343963538225568"
                },
                {
                    "uniacid": 17, //Dr.Newmann
                    "api": "606398301706754"
                },
                {
                    "uniacid": 19, //ACE
                    "api": "2012362119109138"
                },
                {
                    "uniacid": 22, //Ho Chun
                    "api": "1019123899114733"
                },
                {
                    "uniacid": 20, // Unique Fencing
                    "api": "674458627910520"
                },
                {
                    "uniacid": 24, // SK Easy
                    "api": "3505494359675361"
                }
            ];
            let data = fblist.find(item => item.uniacid === this.uniacid);
            let fbApi = data.api;

            //console.log("fbApi: " + fbApi)

            window.fbAsyncInit = function() {
                window.FB.init({
                    appId: fbApi, //You will need to change this
                    cookie: true, // This is important, it's not enabled by default
                    version: "v17.0"
                });
            };
        },
        async loadFacebookSDK(d, s, id) {
            var js,
            fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {
                return;
            }
            js = d.createElement(s);
            js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        },

        // 发送驗證碼
        async sendMobileCode() {
            console.log(this.$http)
            if (!this.form.mobile) return this.$message({ message: '請輸入手機號',type: 'warning'});
            const res = await this.$http.post('/validate/sendMobileCode', this.form)
            if (res.code !== 0) return this.$message.error(res.message)
            let data = res.data
            this.form.code = data.code
            countdown.start(data.timer, (d, s) => {
                this.sendCodeText = Number(s).toFixed(0) + 's'
            }, () => {
                this.sendCodeText = '驗證碼'
            })
        },
        // 手机号登录
        async loginSubmit() {
    // Check for missing mobile number
    if (!this.form.mobile) return this.$message({ message: this.$t('Please enter phone'), type: 'error' });
    if (this.loginMethod == 'password' && !this.form.password) return this._showToast('請輸入密碼');
    if (this.loginMethod == 'mobile' && !this.form.code) return this.$message({ message: '請輸入驗證碼', type: 'warning' });

    const api = '/login' + (this.loginMethod == 'mobile' ? '/sms' : '');
    const res = await this.$http.post(api, this.form);

    if (res.code !== 0) {
        return this.$message.error(res.message);
    }

    window.localStorage.setItem('user_id', res.data.user_id);
    window.localStorage.setItem('username', this.form.mobile); 

    this._loginSuccessCallback(res.data, true);

    const cartRes = await this.$http.get('/cart', {
        uniacid: window.localStorage.getItem('uniacid')
    });

    let data = cartRes.data;
    let dataList = [];
    let invalidList = [];
    
    data.items.data.list.forEach(item => {
        if (item.is_invalid) {
            invalidList.push(item);
        } else {
            dataList.push(item);
        }
    });

    // Set the data to the component's data properties
    this.dataList = dataList;
    this.invalidList = invalidList;
    this.response = data;
    //this.$store.dispatch('global/updateCartItemsCount', dataList.length);
    window.localStorage.setItem('cartlength', dataList.length);
    console.log("this.dataList", this.dataList.length);
    console.log("this.dataList", this.dataList);
},
 // 手机号reg
		
        async regSubmit() 
           {if (!this.form.email) { return this.$message({ message: this.$t('Please enter email'), type: 'error' });}
            if (!this.form.mobile) return this.$message({ message: this.$t('Please enter Phone number'),type: 'error'});
            if (this.loginMethod == 'password' && !this.form.password) return this._showToast('請輸入密碼');
         //   if (this.loginMethod == 'mobile' && !this.form.code) return this.$message({ message: '請輸入驗證碼',type: 'warning'});
            const api = '/user/registerwithemail';// + (this.loginMethod == 'mobile' ? '/sms' : '')
            const res = await this.$http.post(api, this.form)
			console.log(res);
            let message = res.message;
            if (this.locale.toUpperCase() === 'EN' && message.includes('注册成功')) {
                message = 'Registlation Success';
            }
			
            this.$message({ message: message, type: 'success' });
            setTimeout(() => {
                window.location.reload();
            }, 1000);
			
			return
            window.localStorage.setItem('user_id', res.data.user_id);
            if (res.code !== 0) return this.$message.error(res.message)
          //  this._loginSuccessCallback(res.data, true)
        },

		
	    async forgetpwd(){
            const currentEmail = this.forgetpasswordform.email;
            const lastEmail = this.lastEmail || '';
            const lastCallTime = this.lastCallTime || 0;
            const cooldownPeriod = 1 * 60 * 1000; 
            const currentTime = Date.now();

            if (currentEmail === lastEmail && currentTime - lastCallTime < cooldownPeriod) {
                const remainingTime = Math.ceil((cooldownPeriod - (currentTime - lastCallTime)) / 1000 / 60);
                this.$confirm(`Please wait ${remainingTime} minute(s) before trying again with the same email.`, {
                    confirmButtonText: 'OK',
                    showCancelButton: false,
                    type: 'info',
                });
                return;
            }

            this.lastCallTime = currentTime;
            this.lastEmail = currentEmail;

			//this.$message({ message: '請聯係系統管理員',type: 'success'});
            //console.log('forgetpwd');

            console.log('forgetpwd');
            console.log(this.uniacid);
            var emaillist = [
            {
                    "uniacid": 15, //Alex Au
                    "service": "service_i7iso7c",
                    "template": "template_q3aihip",
                    "api": "V_K_kd8zgoc1TbfFO"
                },
                {
                    "uniacid": 17, //Dr.Newmann
                    "service": "service_bq3vi8c",
                    "template": "template_7umzjba",
                    "api": "9AlvderpThGfNT67k"
                },
                {
                    "uniacid": 19, //ACE
                    "service": "service_vwa1m07",
                    "template": "template_xgz76kk",
                    "api": "H_8c6zkSntZyBRybB"
                },
                {
                    "uniacid": 22, //Ho Chun
                    "service": "service_065c0an",
                    "template": "template_o1j5zei",
                    "api": "Xa1CTNFWE-reiybrI"
                },
                {
                    "uniacid": 20, // Unique Fencing
                    "service": "service_eqgpjwl",
                    "template": "template_w6tkis7",
                    "api": "aeuqp67xbb8BVViRT"
                },
                {
                    "uniacid": 24, // SK Easy
                    "service": "service_yac630i",
                    "template": "template_l7z8rwo",
                    "api": "4wKkS4gd0Zd4UF-Dv"
                },
                {
                    "uniacid": 27, // Trillion
                    "service": "service_dxwctun",
                    "template": "template_23tg4a9",
                    "api": "na9x4syMmIN3PUVmw"
                },
                {
                    "uniacid": 29, // Trillion
                    "service": "service_z6spsoc",
                    "template": "template_prbncii",
                    "api": "hqhqwZZcNHhqIiOLE"
                },
                {
                    "uniacid": 33, // Well Speed
                    "service": "service_zhkh12h",
                    "template": "template_9t1mtcv",
                    "api": "cuOUZQcP3XeoFq8J_"
                },
                {
                    "uniacid": 43, // K520c
                    "service": "service_fi7ac9m",
                    "template": "template_cp0rl96",
                    "api": "FrgSZsm9yHlRrMn0d"
                }
            ];



            let data = emaillist.find(item => item.uniacid === this.uniacid);
            //console.log(data.service);
            let emailService = data.service;
            let emailTemplate = data.template;
            let emailApi = data.api;


            
            
            this.forgetpasswordform.pw = this.makeRandomString(8);
            if (!this.forgetpasswordform.email) return this.$message({ message: '請輸入電郵',type: 'warning'});
            //console.log(this.forgetpasswordform.pw);
            try {


                console.log('SEND MAIL');
                const api = '/user/resetpw';// + (this.loginMethod == 'mobile' ? '/sms' : '')
                const res = await this.$http.post(api, this.forgetpasswordform)
                console.log(res.data);

                if(res.data == "ok")
                emailjs.send(emailService, emailTemplate, this.forgetpasswordform, emailApi)
                .then((result) => {
                    console.log('SUCCESS!', result.text);
                    this.$confirm('已發出電郵', {
                        confirmButtonText: 'OK',
                        showCancelButton: false,
                        type: 'info',});
                }, (error) => {
                    console.log('FAILED...', error.text);
                });
                if (Array.isArray(res.data) && res.data.length === 0) {
                    this.$confirm('Please enter a valid email address', {
                        confirmButtonText: 'OK',
                        showCancelButton: false,
                        type: 'info',
                    });
                }} catch(error) {
                console.log('error')
                console.log({error})
            }
		},

        // 登录成功
        async _loginSuccessCallback(data, back) {
            window.localStorage.setItem('login@token', data.token)
            document.cookie = 'PHPSESSID=' + data.token
            window.localStorage.setItem('user_id', data.user_id)
            this.getUserInfo()
            window.location.hash = '/'
        },

        async _loginFB(fdata) {
            let  res = await this.$http.get('/login/facebooklogin',fdata)
            
            console.log('email res',res );
            
            if (res.code !== 0) return this.$message.error(res.message)

            console.log('login sucess')
            console.log(res.data)
            this._loginSuccessCallback(res.data, true)
        },

        //获取用户信息
        async getUserInfo(e) {
            const user_id = window.localStorage.getItem('user_id') || ''
            let res = null
            if(user_id){
                res = await this.$http.get('/user/profile',{user_id})
            }
            user_id && res.code == 0 && this.$store.commit('saveUserInfo',res.data)
        },
		
		 async onSignInSuccess (response) {
		        // FB.api('/me', dude => {
		        //   console.log(Good to see you, ${dude.name}.)
		        // })
				const token = response.authResponse.accessToken
				const userID = response.authResponse.userID
				
		        console.log(response) //返回第三方的登录信息 tolen等
		        console.log('token',token) //返回第三方的登录信息 tolen等
				
				let  fdata= {
						userid: userID
					}
					 
	            let  res = await this.$http.get('/login/facebooklogin',fdata)
				  
				  console.log('email res',res );
				  
				  if (res.code !== 0) return this.$message.error(res.message)

                  console.log('login sucess')
                  console.log(res.data)
				  this._loginSuccessCallback(res.data, true)
				  
				
		},
		// 获取facebook用户信息
		getFacebookUserinfo(params) {
		  return request({
		    url: '/app/index.php?i=1&c=entry&m=qicheng_messenger&do=api_facebook_info',
		    method: 'get',
		    params
		  })
		},
		onSignInError (error) {
			
		      },

        makeRandomString(length) {
            let result = '';
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            const charactersLength = characters.length;
            let counter = 0;
            while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
            }
            return result;
        }
    }
}
